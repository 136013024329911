<template>
  <div>
    <b-row>
      <b-col md="2" cols="6" v-for="brand in brands" :key="brand._id">
        <b-link :to="`/brand/${brand.slug}`">
          <b-card>
            <div class="view-selector">
              <b-button
                @click.prevent="changeBrandDisplay(brand.isPrivate, brand._id)"
                variant="flat-primary"
                size="sm"
                class="btn-icon"
              >
                <feather-icon
                  :icon="brand.isPrivate ? 'EyeOffIcon' : 'EyeIcon'"
                ></feather-icon>
              </b-button>
            </div>
            <div class="text-center">
              <div class="brand-logo">
                <img
                  class="w-100"
                  :src="brand.logo.image"
                  :alt="brand.logo.alt"
                />
              </div>
              {{ brand.name }}
            </div>
          </b-card>
        </b-link>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { getUserToken } from "@/auth/utils";
import axios from "axios";
import { BRow, BCol, BCard, BLink, BButton } from "bootstrap-vue";

export default {
  data() {
    return {
      brands: [],
    };
  },
  components: {
    BRow,
    BCol,
    BCard,
    BLink,
    BButton,
  },
  methods: {
    getBrands() {
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/api/brand/get`, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        })
        .then((response) => {
          this.brands = response.data;
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    },
    changeBrandDisplay(state, brand) {
      let isPrivate = "";
      if (!state) {
        isPrivate = true;
      } else {
        isPrivate = false;
      }

      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/brand/update`,
          {
            updates: { isPrivate },
            brand,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          window.location.reload();
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    },
  },
  mounted() {
    this.getBrands();
  },
};
</script>

<style>
.brand-logo {
  max-width: 80px;
  margin: 0 auto;
}
.view-selector {
  position: absolute;
  top: 5px;
  right: 15px;
}
</style>
